import axiosInstance from "./axios";

const fileUpload = (file, onProgress) => {
    const data = new FormData();
    data.append('file', file);
    return axiosInstance.post('file/upload', data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            
            if(onProgress){
              onProgress(Math.round((loaded * 100) / total));
            }
        }
    });
}
export default fileUpload;