import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useState } from 'react';
// form
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { Card, Chip, Grid, Stack, TextField, Typography, Autocomplete, InputAdornment, Alert } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import {
  FormProvider,
  RHFSwitch,
  RHFSelect,
  RHFEditor,
  RHFTextField,
  RHFRadioGroup,
  RHFUploadMultiFile,
  RHFUploadSingleFile,
  RHFUploadSingleVideoFile,
} from '../../../components/hook-form';
import { LinearProgressWithLabel } from '../../../components/ProgressBar';
import fileUpload from '../../../utils/fileUpload';
import axiosInstance from '../../../utils/axios';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import { generateVideoThumbnail, getVideoDuration } from '../../../utils/fileFunctions';


// ----------------------------------------------------------------------





const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

// ----------------------------------------------------------------------



export default function VideoViewForm() {
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();
  const [ categoryList, setCategoryList] = useState([]);
  const isMountedRef = useIsMountedRef();
  const [progress, setProgress] = useState(0);

  const NewVideoSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    long_dsc: Yup.string().max(500,"Max 500 Characters allowed").required('Long Description is required'),
    short_dsc: Yup.string().max(100,"Max 100 Characters allowed").required('Short Description is required'),
    category: Yup.string().required('Category is required'),
    video_url: Yup.string().required('Video URL is required'),
    // price: Yup.number().moreThan(0, 'Price should not be $0.00'),
  });

  const defaultValues = useMemo(
    () => ({
      name: '',
      video_url: '',
      long_dsc: '',
      short_dsc: '',
      category: '',
      thumbnail_url: '',
      duration: 0,
      active: false,
      
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getCategories = useCallback(async () => {
    try {
      const response = await axiosInstance.get('/category/list');
  
      setCategoryList(response.data.data.docs);
     
  
    } catch (err) {
      //
    }
  }, [isMountedRef]);

  useEffect(() => {
    getCategories();
  },[getCategories]);

  const methods = useForm({
    resolver: yupResolver(NewVideoSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setError,
    setValue,
    getValues,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = methods;

  const values = watch();

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file) {
        setValue(
          'video_url',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );

  const onSubmit = async () => {
    try {
      setProgress(0);
      if (typeof values.video_url !== 'string') {
        setProgress(1);
        const thumbnail =  await generateVideoThumbnail(values.video_url);
        const thumbnailUpload = await fileUpload(thumbnail);
        const duration = await getVideoDuration(values.video_url);
        setValue('thumbnail_url', thumbnailUpload.data.data.uri);
        setValue('duration', duration);
        const res = await fileUpload(values.video_url,(uploadProgress)=>{
          setProgress(uploadProgress);
        });
        setValue('video_url', res.data.data.uri);
      }
      await axiosInstance.post(`/video/create`, getValues())
      // await new Promise((resolve) => setTimeout(resolve, 500));
      // reset();
      enqueueSnackbar('Create success!' );
       navigate(PATH_DASHBOARD.video.list);
    } catch (error) {
      console.error(error);
      if (isMountedRef.current) {
        setError('afterSubmit', error);
      }
    }
  };


  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      {!!errors.afterSubmit && <Alert sx={{mb:2}} severity="error">{errors.afterSubmit.message}</Alert>}
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Stack spacing={3}>
              <RHFTextField name="name" label="Video Name" />

              <div>
                
                <RHFTextField name="short_dsc" label="Short Description"  />
                
              </div>

              <div>
                
                <RHFTextField name="long_dsc" label="Long Description" multiline rows={5} />
                
              </div>

              <div>
                <LabelStyle>Video</LabelStyle>
                <RHFUploadSingleVideoFile name="video_url" accept="video/*" maxSize={104857600} onDrop={handleDrop} />
              </div>
            </Stack>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Stack spacing={3}>
            <Card sx={{ p: 3 }}>
            <Stack spacing={3} mt={2}>
              <RHFSwitch name="active" label="Active" />

              <RHFSelect name="category" label="Category">
              <option value="" />
                  {categoryList.map((val) => (
                    
                     
                        <option key={val.name} value={val.id}>
                          {val.name}
                        </option>
                      
                    
                  ))}
                </RHFSelect>
              </Stack>

              {/* <Stack spacing={3} mt={2}>
                <RHFTextField name="code" label="Product Code" />
                <RHFTextField name="sku" label="Product SKU" />

                <div>
                  <LabelStyle>Gender</LabelStyle>
                  <RHFRadioGroup
                    name="gender"
                    options={GENDER_OPTION}
                    sx={{
                      '& .MuiFormControlLabel-root': { mr: 4 },
                    }}
                  />
                </div>

                <RHFSelect name="video" label="Video">
                  {CATEGORY_OPTION.map((video) => (
                    <optgroup key={video.group} label={video.group}>
                      {video.classify.map((classify) => (
                        <option key={classify} value={classify}>
                          {classify}
                        </option>
                      ))}
                    </optgroup>
                  ))}
                </RHFSelect>

                <Controller
                  name="tags"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      multiple
                      freeSolo
                      onChange={(event, newValue) => field.onChange(newValue)}
                      options={TAGS_OPTION.map((option) => option)}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip {...getTagProps({ index })} key={option} size="small" label={option} />
                        ))
                      }
                      renderInput={(params) => <TextField label="Tags" {...params} />}
                    />
                  )}
                />
              </Stack> */}
              
            </Card>



            {isSubmitting && progress>0 && <LinearProgressWithLabel value={progress} />}
            {<LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
              {'Create'}
            </LoadingButton>}
          </Stack>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
