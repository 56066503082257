/* eslint-disable camelcase */
import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Avatar, Checkbox, TableRow, TableCell, Typography, MenuItem } from '@mui/material';
// components
import { format } from 'date-fns';
import Label from '../../../../components/Label';
import Iconify from '../../../../components/Iconify';
import { TableMoreMenu } from '../../../../components/table';

// ----------------------------------------------------------------------

GSIUserTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onViewRow: PropTypes.func,
  onAction: PropTypes.func,
};

export default function GSIUserTableRow({ row, selected, onViewRow, onAction }) {
  const theme = useTheme();

  const { id, first_name, last_name, business, email, type, gsi_accepted, gsi_accepted_at, last_login_at, createdAt } = row;

  const [openMenu, setOpenMenuActions] = useState(null);

  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  return (
    <TableRow hover selected={selected}>
      {/* <TableCell padding="checkbox">
        <Checkbox checked={selected} onClick={onSelectRow} />
      </TableCell> */}

      {/* <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
        <Avatar alt={name} src={avatarUrl} sx={{ mr: 2 }} />
        <Typography variant="subtitle2" noWrap>
          {name}
        </Typography>
      </TableCell> */}

      <TableCell align="left">{first_name}</TableCell>
      <TableCell align="left">{last_name}</TableCell>
      <TableCell align="left">{email}</TableCell>
      <TableCell align="left"> </TableCell>

      <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
        {type}
      </TableCell>

      <TableCell align="left">{business?.name ?? "None"}</TableCell>
      
      <TableCell align="left">
       {last_login_at && format(new Date(last_login_at), 'MMM dd, yyyy')}
      </TableCell>
      <TableCell align="left">
       {createdAt && format(new Date(createdAt), 'MMM dd, yyyy')}
      </TableCell>
      <TableCell align="center">
        <Iconify
          icon={gsi_accepted ? 'eva:checkmark-circle-fill' : 'eva:clock-outline'}
          sx={{
            width: 20,
            height: 20,
            color: 'success.main',
            ...(!gsi_accepted && { color: 'warning.main' }),
          }}
        />
      </TableCell>
      <TableCell align="left">
       {gsi_accepted_at && format(new Date(gsi_accepted_at), 'MMM dd, yyyy')}
       {!gsi_accepted_at && 'Not Accepted'}
      </TableCell>
      

      {/* <TableCell align="right">
        <TableMoreMenu
          open={openMenu}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          actions={
            <>
            
              <MenuItem
                onClick={() => {
                  onViewRow();
                  handleCloseMenu();
                }}
              >
                <Iconify icon={'eva:eye-outline'} />
                View
              </MenuItem>
            </>
          }
        />
      </TableCell> */}
    </TableRow>
  );
}
