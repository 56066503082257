import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Box, CardMedia, FormHelperText, Tab, Tabs, Typography } from '@mui/material';
// type
import { useState } from 'react';
import { capitalCase } from 'change-case';
import { isString } from 'lodash';
import { UploadAvatar, UploadMultiFile, UploadSingleFile } from '../upload';
import UploadSingleAnyFile from '../upload/UploadSingleAnyFile';
import UploadSingleVideoFile from '../upload/UploadSingleVideoFile';
import Iconify from '../Iconify';

// ----------------------------------------------------------------------

RHFUploadAvatar.propTypes = {
  name: PropTypes.string,
};

export function RHFUploadAvatar({ name, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const checkError = !!error && !field.value;

        return (
          <div>
            <UploadAvatar error={checkError} {...other} file={field.value} />
            {checkError && (
              <FormHelperText error sx={{ px: 2, textAlign: 'center' }}>
                {error.message}
              </FormHelperText>
            )}
          </div>
        );
      }}
    />
  );
}

// ----------------------------------------------------------------------

RHFUploadSingleFile.propTypes = {
  name: PropTypes.string,
};

export function RHFUploadSingleFile({ name, accept, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const checkError = !!error && !field.value;

        return (
          <UploadSingleFile
            accept="image/*"
            file={field.value}
            error={checkError}
            helperText={
              checkError && (
                <FormHelperText error sx={{ px: 2 }}>
                  {error.message}
                </FormHelperText>
              )
            }
            {...other}
          />
        );
      }}
    />
  );
}

// ----------------------------------------------------------------------

RHFUploadMultiFile.propTypes = {
  name: PropTypes.string,
};

export function RHFUploadMultiFile({ name, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const checkError = !!error && field.value?.length === 0;

        return (
          <UploadMultiFile
            accept="image/*"
            files={field.value}
            error={checkError}
            helperText={
              checkError && (
                <FormHelperText error sx={{ px: 2 }}>
                  {error?.message}
                </FormHelperText>
              )
            }
            {...other}
          />
        );
      }}
    />
  );
}


RHFUploadSingleAnyFile.propTypes = {
  name: PropTypes.string,
};

export function RHFUploadSingleAnyFile({ name, accept, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const checkError = !!error && !field.value;

        return (
          <UploadSingleAnyFile
            accept="video/*"
            file={field.value}
            error={checkError}
            helperText={
              checkError && (
                <FormHelperText error sx={{ px: 2 }}>
                  {error.message}
                </FormHelperText>
              )
            }
            {...other}
          />
        );
      }}
    />
  );
}

RHFUploadSingleVideoFile.propTypes = {
  name: PropTypes.string,
};

export function RHFUploadSingleVideoFile({ name, accept, ...other }) {
  const TABS = [
    
    {
      value: 'upload',
      icon: <Iconify icon={'fa-solid:upload'} width={15} height={15} />,
      component: <Box />
    },
    {
      value: 'preview',
      icon: <Iconify icon={'bx:video'} width={20} height={20} />,
      component: <Box />
    }
  ];
  const { control } = useFormContext();
  const [currentTab, setCurrentTab] = useState('upload');

  return (
    <Box>
      <Tabs
          value={currentTab}
          scrollButtons="auto"
          variant="scrollable"
          allowScrollButtonsMobile
          onChange={(e, value) => setCurrentTab(value)}
        >
          {TABS.map((tab) => (
            <Tab disableRipple key={tab.value} label={capitalCase(tab.value)} icon={tab.icon} value={tab.value} />
          ))}
        </Tabs>
        <Box sx={{ mb: 2 }} />
          <Box display={currentTab === "preview"?"none":"block"}>
            <Controller
              name={name}
              control={control}
              render={({ field, fieldState: { error } }) => {
                const checkError = !!error && !field.value;

                return (
                  <UploadSingleVideoFile
                    accept="video/*"
                    file={field.value}
                    error={checkError}
                    helperText={
                      checkError && (
                        <FormHelperText error sx={{ px: 2 }}>
                          {error.message}
                        </FormHelperText>
                      )
                    }
                    {...other}
                  />
                );
              }}
            />
          </Box>

          <Box display={currentTab === "upload"?"none":"block"}>
          <Controller
              name={name}
              control={control}
              render={({ field, fieldState: { error } }) => {
                const checkError = !!error && !field.value;
                const file = field.value;
                return (
                  <>
                   {file && (
                      <CardMedia component="video" controls muted src={isString(file) ? file : file.preview} sx={{
                                top: 8,
                                left: 8,
                                borderRadius: 1,
                                
                                width: 'calc(100% - 16px)',
                                height: 'calc(100% - 16px)',
                              }}/>

                  )}
                  {!file && (
                       <UploadSingleVideoFile
                          accept="video/*"
                          file={field.value}
                          error={checkError}
                          helperText={
                            checkError && (
                              <FormHelperText error sx={{ px: 2 }}>
                                {error.message}
                              </FormHelperText>
                            )
                          }
                          {...other}
                        />

                  )}
                  </>
                );
              }}
            />
          </Box>
    </Box>
  );
}