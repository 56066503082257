import { useCallback, useEffect, useState } from 'react';
import { paramCase, sentenceCase } from 'change-case';
import { useParams, useLocation } from 'react-router-dom';
import { format } from 'date-fns';
// @mui
import {
  Container,
  Box,
  Card,
  Stack,
  Table,
  Avatar,
  Button,
  Divider,
  MenuItem,
  TableRow,
  TableBody,
  TableCell,
  IconButton,
  TableHead,
  CardHeader,
  Typography,
  TableContainer,
} from '@mui/material';// redux
import { useDispatch, useSelector } from '../../redux/store';
import { getProducts } from '../../redux/slices/product';
import { _category, _video } from '../../_mock';

// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useSettings from '../../hooks/useSettings';
// components
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import axiosInstance from '../../utils/axios';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import Label from '../../components/Label';
import Iconify from '../../components/Iconify';
// ----------------------------------------------------------------------

export default function CategoryView() {
  const { themeStretch } = useSettings();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isMountedRef = useIsMountedRef();
  const { id = '' } = useParams();
  const [ category, setCategory ] = useState({});
  const getCategory = useCallback(async () => {
    try {
      const response = await axiosInstance.get(`/category/${id}/get`);
  
         setCategory(response.data.data);
        
  
    } catch (err) {
       console.log(err);
    }
  }, [isMountedRef]);

  useEffect(() => {
    getCategory();
  },[getCategory]);

  return (
    <Page title="Category: Category Stats">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={'Category Stats'}
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            {
              name: 'Category',
              href: PATH_DASHBOARD.category.root,
            },
            { name: 'Category Stats'  },
          ]}
        />

        <Card>
        <CardHeader title={`Category Stats: ${category?.name}` }sx={{ mb: 3 }} />
       
          <TableContainer sx={{ minWidth: 720 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ minWidth: 200 }}>Video name</TableCell>
                  <TableCell sx={{ minWidth: 160 }}>Watch Count</TableCell>
                  <TableCell sx={{ minWidth: 160 }}>Unique Views</TableCell>
                  <TableCell sx={{ minWidth: 120 }}>Date &amp; Time</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {_category.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>
                     
                        
                        <Typography variant="subtitle2">{row.name}</Typography>
                      
                    </TableCell>

                    <TableCell>{row.watchCount}</TableCell>
                    
                    <TableCell>{row.uniqueViews}</TableCell>

                    <TableCell>{format(new Date(row.timestamp), 'MM:HH dd MMM yyyy')}</TableCell>
                    
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
      

        <Divider />

        {/* <Box sx={{ p: 2, textAlign: 'right' }}>
          <Button size="small" color="inherit" endIcon={<Iconify icon={'eva:arrow-ios-forward-fill'} />}>
            Export
          </Button>
        </Box> */}
      </Card>
      </Container>
    </Page>
  );
}
