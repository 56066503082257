import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { Button, Card, Container, Grid, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
// routes
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
// form
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import Iconify from '../../components/Iconify';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
// components
import { FormProvider, RHFEditor } from '../../components/hook-form';
// components
import Page from '../../components/Page';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import useSettings from '../../hooks/useSettings';
import { PATH_DASHBOARD } from '../../routes/paths';
import axiosInstance from '../../utils/axios';



const DataSchema = Yup.object().shape({
  content: Yup.string().required('Content is required'),
});
const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.warning,
  marginBottom: theme.spacing(1),
}));

export default function GSI() {
  const { themeStretch } = useSettings();

  const navigate = useNavigate();

  const [data, setData] = useState('');

  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();

  const defaultValues = useMemo(
    () => ({
      content: data,
    }),
    [data]
  );

  const methods = useForm({
    resolver: yupResolver(DataSchema),
    defaultValues,
  });

  const {
    watch,
    setValue,
    getValues,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  const getData = useCallback(async () => {
    try {
      const response = await axiosInstance.get('/information/general_security_information');

      setValue('content',response.data.data.content);

    } catch (err) {
      //
    }
  }, [isMountedRef]);

  useEffect(() => {
    getData();
  }, [getData]);

  const onSubmit = async () => {
    try {
      await axiosInstance.post('/information/general_security_information/update', getValues());
      enqueueSnackbar('Update success!');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Page title="Information: General Security Information">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="General Security Information"
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            { name: 'Information', href: PATH_DASHBOARD.information.root },
            { name: 'General Security Information' },
          ]}
          action={
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:checkmark-circle-2-outline" />}
              component={RouterLink}
              to={PATH_DASHBOARD.information.gsi_user_list}
            >
              Accepted Users List
            </Button>
          }
        />

        <Card>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <Card sx={{ p: 3 }}>
                  <Stack spacing={3}>
                    <div>
                     
                      <RHFEditor name="content" />
                    </div>
                    <LabelStyle>*Note: After update, all users have to re-accept the new General Security Information in App</LabelStyle>
                    <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
                      Update
                    </LoadingButton>
                  </Stack>
                </Card>
              </Grid>
            </Grid>
          </FormProvider>
        </Card>
      </Container>
    </Page>
  );
}
