import { paramCase } from 'change-case';
import { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import {
  Box,
  Tab,
  Tabs,
  Card,
  Table,
  Switch,
  Button,
  Tooltip,
  Divider,
  TableBody,
  Container,
  IconButton,
  TableContainer,
  TablePagination,
  FormControlLabel,
} from '@mui/material';
// routes
import { CsvBuilder } from 'filefy';
import axiosInstance from '../../utils/axios';
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useTabs from '../../hooks/useTabs';
import useSettings from '../../hooks/useSettings';
import useTable, { getComparator, emptyRows } from '../../hooks/useTable';
// _mock_
import { _userList } from '../../_mock';
// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import Scrollbar from '../../components/Scrollbar';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { TableEmptyRows, TableHeadCustom, TableNoData, TableSelectedActions } from '../../components/table';
// sections
import { GSIUserTableToolbar, GSIUserTableRow } from '../../sections/@dashboard/gsi-user/list';
import useIsMountedRef from '../../hooks/useIsMountedRef';

// ----------------------------------------------------------------------

const STATUS_OPTIONS = ['all'];

const ROLE_OPTIONS = [
 // 'all',
  'electrician',
  'apprentice',
  'business',
];

const TABLE_HEAD = [
  { id: 'first_name', label: 'First Name', align: 'left' },
  { id: 'last_name', label: 'Last Name', align: 'left' },
  { id: 'email', label: 'Email', align: 'left' },
  { id: 'phone', label: 'Phone', align: 'left' },
  { id: 'type', label: 'Type', align: 'left' },
  { id: 'business', label: 'Biz Name', align: 'left' },
  { id: 'last_login_at', label: 'Last Login', align: 'left' },
  { id: 'createdAt', label: 'Registered On', align: 'left' },
  { id: 'gsi_accepted', label: 'GSI Accepted', align: 'center' },
  { id: 'gsi_accepted_at', label: 'GSI Accepted On', align: 'left' },
];

// ----------------------------------------------------------------------

export default function GSIUserList() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const { themeStretch } = useSettings();
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const [tableData, setTableData] = useState([]);

  const [filterEmail, setfilterEmail] = useState('');

  const [filterRole, setFilterRole] = useState('electrician');

  const isMountedRef = useIsMountedRef();

  const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } = useTabs('all');


  const getUsers = useCallback(async () => {
    try {
      const response = await axiosInstance.get('/information/general_security_information/users/list');
  
         setTableData(response.data.data.results);
  
    } catch (err) {
      //
    }
  }, [isMountedRef]);

  useEffect(() => {
    getUsers();
  },[getUsers]);

  const handlefilterEmail = (filterEmail) => {
    setfilterEmail(filterEmail);
    setPage(0);
  };

  const exportCSV = useCallback(() => {
    const builder = new CsvBuilder('gsi-stats-export.csv');
    const data = tableData.map(row => [`${row.first_name} ${row.last_name}`, row.email, "" ,row.type, row.business?.name ?? "None", row.last_login_at, row.createdAt, "Yes" ,row.gsi_accepted_at]);
    builder
    .setDelimeter(',')
    .setColumns(['Name', 'Email', "Phone", 'Type', 'Biz' ,'Last Login', 'Registered on', 'GSI Accepted', 'Accepted On' ])
    .addRows(data)
    .exportFile();
  });

  const handleFilterRole = (event) => {
    setFilterRole(event.target.value);
  };

  const handleDeleteRow = (id) => {
    const deleteRow = tableData.filter((row) => row.id !== id);
    setSelected([]);
    setTableData(deleteRow);
  };

  const handleDeleteRows = (selected) => {
    const deleteRows = tableData.filter((row) => !selected.includes(row.id));
    setSelected([]);
    setTableData(deleteRows);
  };

  const handleViewRow = (id) => {
    navigate(PATH_DASHBOARD.user.view(paramCase(id)));
  };

  const handleActionRow = async (id, active) => {
    try {
      await axiosInstance.post(`users/${id}/update-status`, { active });
      const actionRow = tableData.map((row) => {
        
        if (row.id === id) {
          row.active = active;
        }
        return row;
      });
      setTableData(actionRow);
      enqueueSnackbar("User updated", { variant: 'success' });
         
  
    } catch (err) {
      enqueueSnackbar("Failed to update", { variant: 'error' });
    }
    
  };

  const dataFiltered = applySortFilter({
    tableData,
    comparator: getComparator(order, orderBy),
    filterEmail,
    filterRole,
    filterStatus,
  });

  const denseHeight = dense ? 52 : 72;

  const isNotFound =
    (!dataFiltered.length && !!filterEmail) ||
    (!dataFiltered.length && !!filterRole) ||
    (!dataFiltered.length && !!filterStatus);

  return (
    <Page title="GSI: Accepted User List">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="GSI: Accepted User List"
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            { name: 'Information', href: PATH_DASHBOARD.information.root },
            { name: 'General Security Information', href: PATH_DASHBOARD.information.general_security_information },
            { name: 'User List' },
          ]}
          action={
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:download-fill" />}
              onClick={exportCSV}
            >
              Export CSV
            </Button>
          }
          
          
        />

        <Card>
          {/* <Tabs
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            value={filterStatus}
            onChange={onChangeFilterStatus}
            sx={{ px: 2, bgcolor: 'background.neutral' }}
          >
            {STATUS_OPTIONS.map((tab) => (
              <Tab disableRipple key={tab} label={tab} value={tab} />
            ))}
          </Tabs> */}

         

          <GSIUserTableToolbar
            filterEmail={filterEmail}
            filterRole={filterRole}
            onFilterEmail={handlefilterEmail}
            onFilterRole={handleFilterRole}
            optionsRole={ROLE_OPTIONS}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              {selected.length > 0 && (
                <TableSelectedActions
                  dense={dense}
                  numSelected={selected.length}
                  rowCount={tableData.length}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id)
                    )
                  }
                  actions={
                    <Tooltip title="Delete">
                      <IconButton color="primary" onClick={() => handleDeleteRows(selected)}>
                        <Iconify icon={'eva:trash-2-outline'} />
                      </IconButton>
                    </Tooltip>
                  }
                />
              )}

              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.length}
                  numSelected={selected.length}
                  onSort={onSort}
                  // onSelectAllRows={(checked) =>
                  //   onSelectAllRows(
                  //     checked,
                  //     tableData.map((row) => row.id)
                  //   )
                  // }
                />

                <TableBody>
                  {dataFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                    <GSIUserTableRow
                      key={row.id}
                      row={row}
                      selected={selected.includes(row.id)}
                      onSelectRow={() => onSelectRow(row.id)}
                      onViewRow={() => handleViewRow(row.id)}
                      onAction={(active) => handleActionRow(row.id, active)}
                    />
                  ))}

                  <TableEmptyRows height={denseHeight} emptyRows={emptyRows(page, rowsPerPage, tableData.length)} />

                  <TableNoData isNotFound={isNotFound} />
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={dataFiltered.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />

            <FormControlLabel
              control={<Switch checked={dense} onChange={onChangeDense} />}
              label="Dense"
              sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
            />
          </Box>
        </Card>
      </Container>
    </Page>
  );
}

// ----------------------------------------------------------------------

function applySortFilter({ tableData, comparator, filterEmail, filterStatus, filterRole }) {
  const stabilizedThis = tableData.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  tableData = stabilizedThis.map((el) => el[0]);

  if (filterEmail) {
    tableData = tableData.filter((item) => item.email.toLowerCase().indexOf(filterEmail.toLowerCase()) !== -1);
  }

  if (filterStatus !== 'all') {
    tableData = tableData.filter((item) => item.active === (filterStatus === 'active'));
  }

  if (filterRole !== 'all') {
    tableData = tableData.filter((item) => item.type.toLowerCase() === filterRole);
  }

  return tableData;
}
